import styled from 'styled-components';
import { Input } from '../../../inputs/Input';

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 12px;
  border-radius: 8px;

  &:hover {
    background: var(--search-bar-background);
  }
  &:focus-within {
    background: var(--search-bar-background);
  }
`;

export const StyledInput = styled(Input)`
  position: relative;
  color: var(--font-color-primary);
  background: transparent;
  padding-left: 12px;
  border-radius: 0;
  border-color: transparent;

  &:hover {
    border-color: transparent;
  }

  &:focus {
    background: var(--background-color-secondary);
    border-color: transparent;
    color: var(--font-color-primary);
  }

  &::placeholder {
    color: var(--font-color-secondary--light);
    font-weight: 500;
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;
