import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import { useTranslation } from '../../../i18n';
import {
  layoutOptions,
  WIDE,
  generateWidgetCode,
  generateWidgetTriggerCode,
} from './utils';
import TileSelector from '../../tilesSelector';

function LightboxSection({ experienceUrl, widgetUrl, learnMoreUrl }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'LightboxSection',
  });
  const [layout, setLayout] = useState(WIDE);
  const [widgetCode, setWidgetCode] = useState('');
  const [triggerCode, setTriggerCode] = useState('');

  useEffect(() => {
    const windowId = nanoid();
    const trigger = nanoid();

    setWidgetCode(
      generateWidgetCode({
        layout,
        experienceUrl,
        widgetUrl,
        trigger,
        windowId,
      }),
    );

    setTriggerCode(generateWidgetTriggerCode(trigger));
  }, [layout]);

  const copyCode = (code) => {
    navigator.clipboard.writeText(code);
  };

  const handleLayoutChange = (value) => {
    setLayout(value);
  };

  return (
    <>
      <div className="popup__header">Options</div>
      <form>
        <div className="widget-form__input_wrapper">
          <label className="form__label">LAYOUT</label>
          <div className="popup__hint">{t('layoutHint')}</div>
          <TileSelector
            options={layoutOptions}
            defaultValue={WIDE}
            onChange={handleLayoutChange}
          />
        </div>
      </form>
      <div className="popup__header">Code</div>
      <div className="popup__hint-header">1. Copy & paste hyperlink code</div>
      <div className="popup__hint">
        If you’d like to make your own element trigger a lightbox, copy the code
        below and replace the <code>&lt;a&gt;</code> object with your own
        element. Make sure the <code>data-ha-widget-trigger</code> attribute and
        value are applied.
      </div>
      <textarea
        className="popup__textarea"
        style={{ minHeight: '84px' }}
        rows="3"
        value={triggerCode}
        readOnly
      />
      <button
        className="popup__copy-button popup__copy-button--static button--mobile-hero"
        onClick={() => copyCode(triggerCode)}
        data-action={'widget-tabs#showCopyNotice'}
      >
        Copy Code
      </button>
      <div className="popup__hint-header">
        2. Copy & paste code before &lt;/head&gt; tag
      </div>
      <div className="popup__hint">
        Paste this into your global site header. If your website has multiple
        headers, paste this code in the header of every page that you want a
        button to be shown.{' '}
        <a href={learnMoreUrl} target="_blank">
          Learn more
        </a>
      </div>
      <textarea
        className="popup__textarea"
        style={{ minHeight: '280px' }}
        rows="12"
        value={widgetCode}
        readOnly
      />
      <button
        className="popup__copy-button popup__copy-button--static button--mobile-hero"
        onClick={() => copyCode(widgetCode)}
        data-action={'widget-tabs#showCopyNotice'}
      >
        Copy Code
      </button>
    </>
  );
}

LightboxSection.propTypes = {
  experienceUrl: PropTypes.string.isRequired,
  widgetUrl: PropTypes.string.isRequired,
  learnMoreUrl: PropTypes.string,
};

export default LightboxSection;
