export const initialState = {
  isHidden: false,
  hideWithVariants: false,
  showWithVariants: false,
  variantsCount: 0,
};

export const SET_FORMLIS_HIDDEN = 'SET_FORMLI_HIDDEN';
export const SET_HIDE_WITH_VARIANTS = 'SET_HIDE_WITH_VARIANTS';
export const SET_SHOW_WITH_VARIANTS = 'SET_SHOW_WITH_VARIANTS';
export const SET_VARIANTS_AMOUNT = 'SET_VARIANTS_AMOUNT';
export const SET_FORM = 'SET_FORM';

export function formliReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_FORMLIS_HIDDEN:
      return { ...state, isHidden: payload };
    case SET_HIDE_WITH_VARIANTS:
      return { ...state, hideWithVariants: payload };
    case SET_SHOW_WITH_VARIANTS:
      return { ...state, showWithVariants: payload };
    case SET_VARIANTS_AMOUNT:
      return { ...state, variantsCount: payload };
    case SET_FORM:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
