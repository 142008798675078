import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import api from '../../../api-client';
import _uniqueId from 'lodash/uniqueId';
import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';
import { setValueToStore } from '../../../../sharedStore';
import BrandedLabel from '../../../shared/BrandedLabel';
import UgcButtons from '../UgcButtons';
import QuickAnswerButtons from '../QuickAnswerButtons';
import ProgressBar from '../ProgressBar';
import FormContainer from '../FormContainer';
import BottomBar from '../../../responder/BottomBar';
import DonationAmountsSelector from '../../../responder/DonationAmountsSelector';
import MultipleChoiceSelector from '../../../responder/MultipleChoiceSelector';
import DataFields from '../DataFields';
import SocialShare from '../../../responder/SocialShare';
import BrandingLink from '../../../responder/CustomBranding/components/BrandingLink';

import BlockContext from '../../../../contexts/BlockContext';
import GoalAndMetricContext from '../../../../contexts/GoalAndMetricContext';
import useUpdateReferencesTags from '../../../hooks/useUpdateReferencesTags';
import { toHtmlWithStyledReferences } from '../../../../utils';

import {
  BrandingWrapper,
  Container,
  ContentWrapper,
  BottomWrapper,
  ProgressBarContainer,
  ScrollableContentWrapper,
} from './style';
import SkipButton from '../../../responder/SkipButton';
import NavigationButtons from '../../../shared/NavigationButtons';
import BlockLabel from '../../../shared/BlockLabel';
import CtaButton from '../../../shared/CtaButton';
import StaticTitle from '../StaticTitle';

const ugcTypesOrderMap = {
  video: 0,
  upload: 1,
  text: 2,
};

const prepareDataFields = (items) =>
  items.map(
    ({
      id,
      type,
      richText,
      required,
      hidden,
      showCondition,
      checkboxHidden,
      currency,
      defaultValue,
      cssStyle,
    }) => ({
      id,
      required,
      isHidden: !_isEmpty(showCondition?.conditions?.data) ? false : hidden,
      checkboxHidden,
      type,
      richText,
      multipleChoiceOptions: [],
      currency,
      defaultValue,
      cssStyle,
    }),
  );

const transformMultipleChoiceOptions = (options_from_state) => {
  return options_from_state.map((option) => {
    const randomId = _uniqueId();

    const title = option.richText.title;
    const titleHtml = option.richText.titleHtml;

    return [
      randomId,
      title || '',
      titleHtml || '',
      _omitBy(option.cssStyle, _isEmpty),
    ];
  });
};

const removeEmptyAttributes = (obj) => {
  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

function Block({ preview, ask_uuid, experienceSettings, appSettings }) {
  const [isHidden, setIsHidden] = useState(false);
  const [brandingState, setBrandingState] = useState({ name: '' });
  const [blockState] = useContext(BlockContext);
  const { ctaButtonStyle, ctaButtons, askLabel, titleSetting } = blockState;

  const parsedTitleHtml = useUpdateReferencesTags(blockState.titleHtml);
  const editableTitleHtml = toHtmlWithStyledReferences(parsedTitleHtml);

  const [metricsState, setMetricState] = useContext(GoalAndMetricContext);
  const hasMetricsBar = blockState.metricsChecked;
  const isPlayButtonFirst =
    experienceSettings.videoStartingScreen === 'play_button_first';
  const ugcTypeIsTextOnly =
    blockState.ugcTypes.length === 1 && blockState.ugcTypes[0] === 'text';
  const ugcTypeIsUploadOnly =
    blockState.ugcTypes.length === 1 && blockState.ugcTypes[0] === 'upload';
  const ugcTextWithoutMedia =
    ugcTypeIsTextOnly &&
    !blockState.hasMedia &&
    blockState.type === 'free_response';
  const sortedUgcTypes = [...blockState.ugcTypes].sort(
    (a, b) => ugcTypesOrderMap[a] - ugcTypesOrderMap[b],
  );

  const onPlayButtonClick = () => {
    setIsHidden(false);
  };

  useEffect(() => {
    api.show('ask_metrics', { id: ask_uuid }).then((response) => {
      // Rewrite data in Context only if AskMetric object from backend received.
      // For new block AskMetric will be equal to { data: null }
      if (response.data.data) {
        const attributes = response.data.data.attributes;
        const withoutEmptyAttributes = removeEmptyAttributes(attributes);
        setMetricState((prevState) => ({
          ...prevState,
          ...withoutEmptyAttributes,
        }));
      }
    });

    api.get('branding').then((response) => {
      if (response.data.data) {
        setBrandingState(response.data.data.attributes);
      }
    });
  }, []);

  useEffect(() => {
    if (
      experienceSettings.customizeVideoBlocks &&
      blockState.videoUrl &&
      isPlayButtonFirst
    ) {
      setIsHidden(true);
    }
  }, [experienceSettings.customizeVideoBlocks, blockState.videoUrl]);

  useEffect(() => {
    if (blockState.contentLayout === 'autofit') {
      setValueToStore('titleSetting', blockState.titleSetting);
    }
  }, [blockState.titleSetting, blockState.contentLayout]);

  const filteredDonationAmounts = useMemo(
    () =>
      blockState.paymentOptions
        .filter((item) => !item.deleted)
        .map((value) => ({
          attributes: { amountNumber: value.amount, cssStyle: value.cssStyle },
        })),
    [blockState.paymentOptions],
  );

  const handlePreviewLinkClick = (event) => {
    const linkWrapper = event.target.closest('a');

    if (!linkWrapper) {
      return;
    }

    if (linkWrapper.host === window.location.host) {
      event.preventDefault();
    }
  };

  const renderBlock = (blockType) => {
    switch (blockType) {
      case 'free_response':
        return (
          <UgcButtons
            ugcTypes={sortedUgcTypes}
            isTextOnly={ugcTypeIsTextOnly}
            helperTextIsHidden={
              ugcTypeIsUploadOnly || _isEmpty(blockState.ugcTypes)
            }
            hasMedia={blockState.hasMedia}
            titleHtml={editableTitleHtml}
            titleStyle={titleSetting.cssStyle}
            handlePreviewLinkClick={handlePreviewLinkClick}
            ctaButtonStyle={ctaButtonStyle}
            ctaButtonContent={ctaButtons[0].richText.titleHtml}
            backgroundColorOverlay={
              blockState.backgroundSetting.backgroundColorOverlay
            }
            askLabel={askLabel}
            contentLayout={blockState.contentLayout}
          />
        );

      case 'data_only':
        return (
          <>
            <BlockLabel askLabel={askLabel} isPreview />
            <DataFields
              dataFields={prepareDataFields(blockState.dataFields)}
              disclaimerChecked={blockState.disclaimerChecked}
              disclaimer={blockState.disclaimer}
              contentLayout={blockState.contentLayout}
              isPreview
            />
            <CtaButton
              ctaButtonStyle={ctaButtonStyle}
              ctaButtonContent={ctaButtons[0].richText.titleHtml}
              isPreview
              noMargin
            />
          </>
        );

      case 'quick_answer':
        return <QuickAnswerButtons askLabel={askLabel} />;

      case 'donation':
        return (
          <DonationAmountsSelector
            paymentOptions={filteredDonationAmounts}
            currency={blockState.currency}
            currentAmount={null}
            defaultDonationType={blockState.defaultDonationType}
            donationType={blockState.donationType}
            ctaButtonContent={ctaButtons[1].richText.titleHtml}
            ctaButtonDisabled={preview}
            ctaButtonStyle={ctaButtonStyle}
            isCustomDonation={blockState.customDonation}
            askLabel={askLabel}
            isPreview
          />
        );

      case 'multiple_choice':
        return (
          <div data-testid="Preview/MultipleChoiceOptionsWrapper">
            <MultipleChoiceSelector
              currentValues={[]}
              options={transformMultipleChoiceOptions(
                blockState.multipleChoiceOptions,
              )}
              withOtherOption={blockState.multipleChoiceOther}
              otherOptionId={null}
              isMulti={blockState.multipleChoiceMultiSelect}
              disabled
              titleSize={100}
              ctaButtonContent={ctaButtons[0].richText.titleHtml}
              ctaButtonStyle={ctaButtonStyle}
              askLabel={askLabel}
              isPreview
            />
          </div>
        );

      case 'content':
        return (
          <div data-testid="Preview/NoContentWrapper">
            <BlockLabel askLabel={askLabel} isPreview />
            {blockState.ctaButtonSettingsChecked && (
              <CtaButton
                ctaButtonStyle={ctaButtonStyle}
                ctaButtonContent={ctaButtons[0].richText.titleHtml}
                isPreview
              />
            )}
          </div>
        );
    }
  };

  return (
    <Container
      data-testid="Preview/BlockContainer"
      fontFamily={blockState.fontFamily}
      flexColumn={ugcTextWithoutMedia}
    >
      {experienceSettings.customBranding && (
        <BrandingWrapper>
          <BrandingLink {...brandingState} />
        </BrandingWrapper>
      )}
      {experienceSettings.socialShareEnabled && <SocialShare shareUrl="" />}
      {experienceSettings.showProgressBar && (
        <ProgressBarContainer>
          <ProgressBar blockType={blockState.type} />
        </ProgressBarContainer>
      )}
      <FormContainer
        titleHtml={editableTitleHtml}
        titleStyle={titleSetting.cssStyle}
        handlePreviewLinkClick={handlePreviewLinkClick}
        hasMedia={blockState.hasMedia}
        backgroundSettings={blockState.backgroundSetting}
        backgroundLayout={blockState.backgroundLayout}
        imageUrl={blockState.imageUrl}
        videoUrl={blockState.videoUrl}
        onPlayButtonClick={onPlayButtonClick}
        withShareIcon={experienceSettings.socialShareEnabled}
        staticTitle={ugcTextWithoutMedia}
        contentLayout={blockState.contentLayout}
        hidePlayButton={
          experienceSettings.videoStartingScreen === 'play_button_none'
        }
      >
        {blockState.contentLayout === 'hug_content' ? (
          <ScrollableContentWrapper
            contentVerticalAlignment={blockState.contentVerticalAlignment}
          >
            <StaticTitle
              titleHtml={editableTitleHtml}
              titleStyle={titleSetting.cssStyle}
              handlePreviewLinkClick={handlePreviewLinkClick}
              isUgcText={
                blockState.type === 'free_response' && ugcTypeIsTextOnly
              }
              fontSize={`${blockState.hugContentFontSize}px`}
            />
            {!isHidden && <div>{renderBlock(blockState.type)}</div>}
            <BottomWrapper>
              <SkipButton
                text="Skip question"
                isVisible={blockState.skippableChecked}
              />
              <NavigationButtons
                isVisible={blockState.showNavigationButtons}
                staticPosition={experienceSettings.hideBranding}
                skippable={blockState.skippableChecked}
                buttons={blockState.navigationButtons}
                isPreview
              />
              <BrandedLabel
                isHidden={experienceSettings.hideBranding}
                title={appSettings.brandingTitle}
                link={appSettings.brandingLink}
                backgroundColorOverlay={
                  blockState.backgroundSetting.backgroundColorOverlay
                }
              />
            </BottomWrapper>
          </ScrollableContentWrapper>
        ) : (
          <ContentWrapper
            withStaticTitle={ugcTextWithoutMedia}
            scrollable={blockState.type !== 'multiple_choice'}
          >
            {!isHidden && renderBlock(blockState.type)}
            <BottomWrapper>
              <SkipButton
                text="Skip question"
                isVisible={blockState.skippableChecked}
              />
              <NavigationButtons
                isVisible={blockState.showNavigationButtons}
                staticPosition={experienceSettings.hideBranding}
                skippable={blockState.skippableChecked}
                buttons={blockState.navigationButtons}
                isPreview
              />
              <BrandedLabel
                isHidden={experienceSettings.hideBranding}
                title={appSettings.brandingTitle}
                link={appSettings.brandingLink}
                backgroundColorOverlay={
                  blockState.backgroundSetting.backgroundColorOverlay
                }
              />
            </BottomWrapper>
          </ContentWrapper>
        )}
        {hasMetricsBar && <BottomBar isPreview askMetric={metricsState} />}
      </FormContainer>
    </Container>
  );
}

Block.propTypes = {
  preview: PropTypes.bool.isRequired,
  ask_uuid: PropTypes.string,
  workspace_id: PropTypes.number,
  appSettings: PropTypes.shape({
    brandingTitle: PropTypes.string,
    brandingLink: PropTypes.string,
  }),
  experienceSettings: PropTypes.shape({
    customBranding: PropTypes.bool,
    customizeVideoBlocks: PropTypes.bool,
    hideBranding: PropTypes.bool,
    id: PropTypes.number,
    showProgressBar: PropTypes.bool,
    socialShareEnabled: PropTypes.bool,
    title: PropTypes.string,
    uuid: PropTypes.string,
    videoStartingScreen: PropTypes.string,
  }),
};

export default Block;
