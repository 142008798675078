import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  PillButton,
  ButtonTitle,
  ButtonIcon,
} from './style';
import { ArrowUp, ArrowDown } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';

const NEWEST = 'created_at.desc';
const OLDEST = 'created_at.asc';
const menuItems = {
  [NEWEST]: 'Newest',
  [OLDEST]: 'Oldest',
};

function SortButton({ queryParam }) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const history = useHistory();
  const [sortOrder, setSortOrder] = useState(NEWEST);

  useEffect(() => {
    setSortOrder(queryParam || NEWEST);
  }, []);

  useEffect(() => {
    search.set('sort_by', sortOrder);
    history.push({ search: search.toString() });
  }, [sortOrder]);

  const handleSortChange = () => {
    setSortOrder(sortOrder === NEWEST ? OLDEST : NEWEST);
  };

  return (
    <PillButton data-testid='SortButton' style={{ width: 'auto' }} onClick={handleSortChange}>
      <ButtonTitle style={{ width: 'auto 0' }}>
        {menuItems[sortOrder]}
      </ButtonTitle>
      <ButtonIcon style={{ display: 'flex' }}>
        <ArrowDown size={12} color="#727278" />
        <ArrowUp size={12} color="#727278" />
      </ButtonIcon>
    </PillButton>
  );
}

SortButton.propTypes = {
  queryParam: PropTypes.string,
};

export default SortButton;
