import React, { useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getForms } from '../api';
import ExperiencesList from '../ExperiencesList';
import Pagination from '../Pagination';
import FilterDropdown from './components/FilterDropdown';
import SearchBar from './components/SearchBar';
import SortButton from './components/SortButton';
import FormsListContext from './context';
import { ReferencesProvider } from '../../contexts/ReferencesContext';
import { PaginationWrapper } from './style';
import makeToast from '../toast';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

function ExperiencesListWrapper() {
  const [experiences, setExperiences] = React.useState([]);
  const [pages, setPages] = React.useState(1);
  const history = useHistory();
  const query = useQuery();
  const page = Number(query.get('page')) || 1;
  const filter = query.get('formable_type');
  const search = query.get('search');
  const sort_by = query.get('sort_by');
  const firstRender = useRef(true);

  useEffect(() => {
    getFormsWithParams();
  }, [query]);

  const getFormsWithParams = () => {
    const params = { page };
    if (filter) {
      params.formable_type = filter;
    } else if (firstRender.current) {
      params.formable_type = 'experience';
    }
    if (search) {
      params.search = search;
    }
    if (sort_by) {
      params.sort_by = sort_by;
    }
    getForms(params)
      .then((response) => {
        setExperiences(response.data);
        setPages(response.meta.pages);
      })
      .catch((err) => {
        if (err.message === 'Failed to fetch') {
          makeToast(
            'Please check your internet connection or try again later',
            'error',
          );
        }
      });

    firstRender.current = false;
  };

  const handlePageChange = (_e, value) => {
    query.set('page', value.toString());
    history.push({ search: query.toString() });
  };

  return (
    <ReferencesProvider>
      <FormsListContext.Provider value={{ getForms: getFormsWithParams }}>
        <SearchBar>
          <SortButton queryParam={sort_by} />
        </SearchBar>
        <FilterDropdown queryParam={filter || 'all'} />
        <ExperiencesList experiences={experiences} />
        {pages > 1 && (
          <PaginationWrapper>
            <Pagination
              defaultPage={page}
              count={pages}
              onChange={handlePageChange}
            />
          </PaginationWrapper>
        )}
      </FormsListContext.Provider>
    </ReferencesProvider>
  );
}

ExperiencesListWrapper.propTypes = {
  experiences: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ExperiencesListWrapper;
