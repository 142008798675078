import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDebounce } from '../../hooks';
import { withTranslation } from '../../../i18n';
import { getEmbedCode } from '../../api';
import { SwitchWithTitle } from '../../switch';
import { reducer, initialState } from './reducer';
import { Loader } from './styled';
import TilesSelector from '../../tilesSelector';
import BackgroundVisibleIcon from './images/video-visible.svg';
import BackgroundTransparentIcon from './images/video-transparent.svg';
import BackgroundTileIcon from './images/video-tile-background.png';
import ColorPickerInput from '../../inputs/ColorPickerInput';
import { rgbaObjToString } from '../../../utils';
import {Label} from '../../globals';

const DIMENSIONS = [
  ['fixed', 'Fixed (375 x 660 px)'],
  ['responsive', 'Adjusted (fit-to-content)'],
];
const SHADOWS = [
  ['shadowOff', 'No drop shadow'],
  ['shadowOn', 'Add a drop shadow'],
];

const backgroundOptions = [
  {
    name: 'background_type',
    value: 'visible',
    label: 'Visible',
    testId: 'EmbedSection/videoBackgroundVisible',
    icon: BackgroundVisibleIcon,
  },
  {
    name: 'background_type',
    value: 'transparent',
    label: 'Transparent',
    testId: 'EmbedSection/videoBackgroundTransparent',
    icon: BackgroundTransparentIcon,
  },
];

const makeParams = ({
  dimension,
  shadow,
  background,
  embedSpecificGoal,
  showLoadingSpinner,
  loadingSpinnerColor,
  experienceId,
}) => ({
  embed: {
    experience_uuid: experienceId,
    goal_by_embed: embedSpecificGoal,
    shadow: shadow === 'shadowOn',
    transparent: background === 'transparent',
    responsive: dimension === 'responsive',
    show_loading_spinner: showLoadingSpinner,
    loading_spinner_color: loadingSpinnerColor,
  },
});

const DEBOUNCE_TIMEOUT = 500;
function EmbedSection({ experienceId, t }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { params, isLoading, embedCode } = state;
  const { dimension, shadow } = params;
  const debouncedParams = useDebounce(params, DEBOUNCE_TIMEOUT);
  const changeParam = (paramName) => (value) =>
    dispatch({ type: 'changeParam', payload: { [paramName]: value } });

  const copyCode = () => {
    navigator.clipboard.writeText(embedCode);
  };

  const handleRadionChange = (onChange) => (e) => {
    const input = e.target.previousElementSibling;

    onChange(input.value);
  };

  const renderRadioOptions = (optionName, currentValue, labels, onChange) =>
    labels.map(([name, label], i) => (
      <div key={i} className="widget-form__input_radio-item">
        <input
          type="radio"
          name={optionName}
          value={name}
          checked={currentValue === name}
          readOnly
        />
        <label
          className="form__radio-label"
          htmlFor={name}
          onClick={handleRadionChange(onChange)}
        >
          <span />
          {label}
        </label>
      </div>
    ));

  useEffect(() => {
    getEmbedCode(makeParams({ ...debouncedParams, experienceId })).then(
      ({ code }) => {
        dispatch({ type: 'updateEmbedCode', payload: { code } });
      },
    );
  }, [debouncedParams, experienceId]);

  const handleLoadingSpinnerColorChange = (value) => {
    changeParam('loadingSpinnerColor')(rgbaObjToString(value));
  };

  return (
    <>
      <div className="popup__header">Options</div>
      <form>
        <div className="widget-form__input_wrapper">
          <label className="form__label">BACKGROUND</label>
          <div className="popup__hint">
            "Transparent" only applies to color backgrounds. Your uploaded
            images and videos will always be visible.
          </div>
          <TilesSelector
            defaultValue="visible"
            options={backgroundOptions}
            onChange={changeParam('background')}
            tileBackground={BackgroundTileIcon}
          />
        </div>
        <div className="widget-form__input_wrapper">
          <label className="form__label">DROP SHADOW</label>
          <div className="popup__hint">
            This will add a light drop shadow behind the iframe.
          </div>
          {renderRadioOptions('shadow', shadow, SHADOWS, changeParam('shadow'))}
        </div>
        <div className="widget-form__input_wrapper">
          <label className="form__label">DIMENSIONS</label>
          <div className="popup__hint">{t('EmbedSection.dimensionsHint')}</div>
          {renderRadioOptions(
            'dimension',
            dimension,
            DIMENSIONS,
            changeParam('dimension'),
          )}
        </div>
      </form>
      <div className="popup__header mb-8">Advanced Settings</div>
      <div className="popup__hint">
        <SwitchWithTitle
          title="Embed-specific goals"
          onChange={(e) => changeParam('embedSpecificGoal')(e.target.checked)}
          data-testid="EmbedSection/EmbedSpecificGoalSwitcher"
          noborder="true"
        />
        {t('EmbedSection.settingsHint')}
      </div>
      {params.dimension === 'fixed' && (
        <SwitchWithTitle
          title="Show loading spinner"
          subtitle={t('EmbedSection.loadingSpinnerHint')}
          checked={params.showLoadingSpinner}
          onChange={(e) => changeParam('showLoadingSpinner')(e.target.checked)}
          data-testid="EmbedSection/ShowLoadingSpinnerSwitcher"
          noborder="true"
        >
          <Label>Spinner color</Label>
          <ColorPickerInput
            color={params.loadingSpinnerColor}
            onChange={handleLoadingSpinnerColorChange}
            onChangeComplete={handleLoadingSpinnerColorChange}
            withoutColorsBar
            withAlpha
          />
        </SwitchWithTitle>
      )}
      <div className="popup__header">Code</div>
      <div className="popup__hint-header">
        Copy HTML code into the Page &lt;body&gt;
      </div>
      <div className="popup__hint">{t('EmbedSection.codeHint')}</div>
      {isLoading && <Loader />}
      <textarea
        className="popup__textarea popup__textarea--hero"
        style={{ minHeight: '280px' }}
        rows="12"
        value={embedCode}
        readOnly
      />
      <button
        className="popup__copy-button button--mobile-hero"
        onClick={copyCode}
        data-action={'widget-tabs#showCopyNotice'}
      >
        Copy Code
      </button>
    </>
  );
}

EmbedSection.propTypes = {
  experienceId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export { EmbedSection };
export default withTranslation()(EmbedSection);
