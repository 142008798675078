import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../checkbox';
import { useFormContext } from '../ExperienceListItem/context';
import { SET_HIDE_WITH_VARIANTS } from '../ExperienceListItem/reducer';
import { useTranslation } from '../../../../i18n';

function HideVariantsCheckbox({ variantsCount }) {
  const { formState, formDispatch } = useFormContext();
  const { hideWithVariants } = formState;
  const { t } = useTranslation('translation', {
    keyPrefix: 'HideFormliModal',
  });

  useEffect(() => {
    return () => {
      formDispatch({
        type: SET_HIDE_WITH_VARIANTS,
        payload: false,
      });
    };
  }, []);

  const handleChange = () => {
    formDispatch({
      type: SET_HIDE_WITH_VARIANTS,
      payload: !hideWithVariants,
    });
  };

  return (
    <>
      <p>{t('content')}</p>
      {!!variantsCount && (
        <Checkbox
          value={hideWithVariants}
          checkedByDefault={hideWithVariants}
          onChange={handleChange}
          label={t('hideVariants', { amount: variantsCount })}
          data-testid="VariantsCheckbox"
        />
      )}
    </>
  );
}

HideVariantsCheckbox.propTypes = {
  variantsCount: PropTypes.number.isRequired,
};

export default HideVariantsCheckbox;
