import styled from 'styled-components';
import { EyeOff } from 'react-feather';

export const HiddenIcon = styled(EyeOff)`
  color: var(--font-color-secondary--light);
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
`;
