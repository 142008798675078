import { createContext, useContext } from 'react';

const FormContext = createContext({
  formState: {
    variantsCount: 0,
  },
});

export function useFormContext() {
  return useContext(FormContext);
}

export default FormContext;
