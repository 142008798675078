import React from 'react';
import PropTypes from 'prop-types';
import DarkModal from '../DarkModal';
import { useTranslation } from '../../../i18n';

function HideFormliModal({
  isOpen,
  hideModal,
  handleHide,
  formableType,
  content,
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'HideFormliModal',
  });

  const buttons = [
    { title: 'Cancel', onClick: hideModal },
    { title: 'Hide', onClick: handleHide, primary: true },
  ];

  return (
    <DarkModal
      isOpen={isOpen}
      title={formableType === 'Variant' ? t('variantTitle') : t('title')}
      content={content}
      buttons={buttons}
      hideModal={hideModal}
    />
  );
}

HideFormliModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  handleHide: PropTypes.func.isRequired,
  variantsCount: PropTypes.number,
  formableType: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default HideFormliModal;
