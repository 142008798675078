import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  margin-bottom: 82px;
  margin-top: 24px;

  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;
