import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';
import Dropdown from '../../../dropdown';
import { useTranslation } from '../../../../i18n';
import { useHistory, useLocation } from 'react-router-dom';
import { PillButton, ButtonTitle, ButtonIcon } from '../SortButton/style';

function FilterDropdown({ queryParam }) {
  const { t } = useTranslation('translation', { keyPrefix: 'ExperienceList' });
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const history = useHistory();

  const menuItems = [
    { title: 'All', value: 'all' },
    { title: t('FilterDropdown.expTitle'), value: 'experience' },
    { title: 'Variants', value: 'variant' },
  ];

  useEffect(() => {
    if (queryParam === 'all') {
      search.set('formable_type', 'experience');
      history.push({ search: search.toString() });
    }
  }, []);

  const handleDropdownChange = (value) => {
    search.delete('page');
    if (value === 'all') {
      search.delete('formable_type');
      history.replace({ search: search.toString() });
    } else {
      search.set('formable_type', value);
      history.push({ search: search.toString() });
    }
  };

  const actionButton = (
    <PillButton style={{ width: 'auto' }}>
      <ButtonTitle style={{ margin: 'auto 0' }}>
        Filter by: {menuItems.find((item) => item.value === queryParam).title}
      </ButtonTitle>
      <ButtonIcon>
        <ChevronDown size={16} color="#727278" />
      </ButtonIcon>
    </PillButton>
  );

  return (
    <Dropdown
      actionButton={actionButton}
      menuItems={menuItems}
      onSelection={handleDropdownChange}
      listStyle={{ left: 0 }}
    />
  );
}

FilterDropdown.propTypes = {
  queryParam: PropTypes.string,
};

export default FilterDropdown;
