import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import TitleWrapper from '../PreviewTitle/TitleWrapper';
import Box from '@mui/material/Box';

const calculateTitleClasses = (title) => {
  const classes = [
    'ha-form__old-title',
    'ha-form__old-title--preview',
    'heading',
  ];

  const size = title.length;

  if (size <= 30) {
    classes.push('ha-form__old-title--xxl');
  } else if (size > 30 && size <= 54) {
    classes.push('ha-form__old-title--xl');
  } else if (size > 54 && size <= 80) {
    classes.push('ha-form__old-title--lg');
  } else if (size > 80 && size <= 120) {
    classes.push('ha-form__old-title--md');
  } else if (size > 120 && size <= 180) {
    classes.push('ha-form__old-title--sm');
  } else if (size > 180 && size <= 250) {
    classes.push('ha-form__old-title--xs');
  } else if (size > 250 && size <= 300) {
    classes.push('ha-form__old-title--xxs');
  } else if (size > 300 && size <= 350) {
    classes.push('ha-form__old-title--xxxs');
  } else if (size > 350) {
    classes.push('ha-form__old-title--xxxxs');
  }

  return classes.join(' ');
};

const regex = /(<([^>]+)>)/gi;

export default function StaticTitle({
  titleHtml,
  titleStyle,
  handlePreviewLinkClick,
  isUgcText,
  fontSize,
}) {
  const preparedTitle = titleHtml.replace(regex, '');

  if (_isEmpty(preparedTitle)) {
    return <div />;
  }

  return (
    <TitleWrapper
      staticTitle
      sx={{
        fontSize,
        position: 'static',
        height: 'auto',
        overflow: 'visible',
        '&& p, && h1, && h2, && h3, && h4': {
          lineHeight: 1.4,
        },
        '&& span': {
          pr: '1px',
        },
      }}
      className={isUgcText ? calculateTitleClasses(preparedTitle) : ''}
    >
      <Box sx={{ borderStyle: 'solid', borderWidth: 0, ...titleStyle }}>
        <div
          onClick={handlePreviewLinkClick}
          dangerouslySetInnerHTML={{ __html: titleHtml }}
        />
      </Box>
    </TitleWrapper>
  );
}
