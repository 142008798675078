import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ExperiencesListWrapper from '../ExperiencesListWrapper';

function FormsPage() {
  return (
    <Router>
      <Switch>
        <Route exact path={['/:workspace_id/forms', '/']} component={ExperiencesListWrapper} />
      </Switch>
    </Router>
  );
}

export default FormsPage;
