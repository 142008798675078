import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DollarSign, MoreHorizontal, LogOut } from 'react-feather';
import Dropdown from '../../../dropdown';
import { FlexRowSpaceBetween, FlexRow } from '../../../globals';
import { Container, IconWrapper, Title, DropdownIconWrapper } from './style';
import Modal from '../../../modal';
import ActionsList from '../ActionsList';
import { deleteEvent } from '../../../api';
import { useTranslation } from '../../../../i18n';

const headerAttributes = {
  experience_completion: {
    icon: <LogOut size={14} strokeWidth={3} />,
    title: (t) => t('experienceCompletionLabel'),
  },
  payment_completion: {
    icon: <DollarSign size={14} strokeWidth={3} />,
    title: (t) => t('paymentCompletionLabel'),
  },
  payment_failed: {
    icon: <DollarSign size={14} strokeWidth={3} />,
    title: (t) => t('paymentFailedLabel'),
  },
};

const menuItems = [{ title: 'Delete Event', value: 'delete' }];

export const EventCard = ({ attributes, deleteUrl, newActionUrl }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'EventCard',
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { eventType, activeActions } = attributes;

  const hideModal = () => {
    setModalIsOpen(false);
  };

  const showModal = () => {
    setModalIsOpen(true);
  };

  const handleDeleteEvent = () => {
    deleteEvent(deleteUrl).then((response) => {
      if (response.ok) {
        window.location.reload();
      }
    });

    hideModal();
  };

  const modalButtons = [
    { title: 'Cancel', onClick: hideModal },
    { title: 'Delete', onClick: handleDeleteEvent, primary: true },
  ];

  const handleDropdownItemClick = (value) => {
    if (value === 'delete') {
      showModal();
    }
  };

  return (
    <Container data-testid="ExperienceEvents/EventCard">
      <FlexRowSpaceBetween margin="0 0 12px 4px">
        <FlexRow>
          <IconWrapper>{headerAttributes[eventType].icon}</IconWrapper>
          <Title data-testid="EventCard/Title">
            {headerAttributes[eventType].title(t)}
          </Title>
        </FlexRow>
        <Dropdown
          actionButton={
            <DropdownIconWrapper data-testid="EventCard/DropdownButton">
              <MoreHorizontal size={26} />
            </DropdownIconWrapper>
          }
          menuItems={menuItems}
          onSelection={handleDropdownItemClick}
          listStyle={{ left: '-102px', top: '4px', width: '140px' }}
        />
      </FlexRowSpaceBetween>
      <ActionsList list={activeActions} />
      <div
        className="experience_show__block-add-item"
        style={{ marginBottom: 0, marginTop: '14px' }}
      >
        <a
          className="experience_show__block-list-item-link link-with-no-effect center"
          href={newActionUrl}
        >
          <div className="experience-block__content-wrapper">+ New Action</div>
        </a>
      </div>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          title="Confirm"
          content="Are you sure you want to delete this event?"
          buttons={modalButtons}
          hideModal={hideModal}
        />
      )}
    </Container>
  );
};

EventCard.propTypes = {
  attributes: PropTypes.shape({
    eventType: PropTypes.string.isRequired,
  }),
  deleteUrl: PropTypes.string.isRequired,
  newActionUrl: PropTypes.string.isRequired,
};
