import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { EventCard } from '../EventCard';
import toast from '../../../toast';
import { ReferencesProvider } from '../../../../contexts/ReferencesContext';

const EventsList = ({ list, successMessage }) => {
  useEffect(() => {
    if (successMessage) {
      toast(successMessage, 'success');
    }
  }, []);

  return (
    <ReferencesProvider>
      {list.map(({ attributes, links }) => {
        return (
          <EventCard
            key={attributes.uuid}
            newActionUrl={links.newActionUrl}
            attributes={attributes}
            deleteUrl={links.deleteUrl}
          />
        );
      })}
    </ReferencesProvider>
  );
};

EventsList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        eventType: PropTypes.string.isRequired,
      }),
      links: PropTypes.shape({
        newActionUrl: PropTypes.string.isRequired,
        deleteUrl: PropTypes.string.isRequired,
      }),
    }),
  ),
  successMessage: PropTypes.string,
};

export default EventsList;
