import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledInput, InputWrapper } from './style';
import { Search } from 'react-feather';
import useDebounce from '../../../hooks/useDebounce';

function SearchBar({ children }) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const history = useHistory();
  const defaultValue = search.get('search') || '';

  const [value, setValue] = useState(defaultValue);
  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    if (debouncedValue) {
      search.set('search', debouncedValue);
      history.push({ search: search.toString() });
    } else {
      search.delete('search');
      history.replace({ search: search.toString() });
    }
  }, [debouncedValue]);

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
  };

  return (
    <InputWrapper>
      <Search color="#727278" />
      <StyledInput
        name="searchBar"
        placeholder="Find a form..."
        onChange={handleChange}
        value={value}
        noMargin
      />
      {children}
    </InputWrapper>
  );
}

SearchBar.propTypes = {
  children: PropTypes.node,
};

export default SearchBar;
