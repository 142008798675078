import React from 'react';
import PropTypes from 'prop-types';
import DragAndDropBlockList from './../DragAndDropBlockList';
import { ReferencesProvider } from '../../../contexts/ReferencesContext';

const getItems = (items) => {
  return items.map(({ attributes }) => ({
    title: attributes.title,
    blockType: attributes.blockType,
    displayBlockType: attributes.displayBlockType,
    blockOrder: attributes.blockOrder,
    responsesCount: attributes.responsesCount,
    id: attributes.id,
    backgroundColor: attributes.backgroundColor,
    blockThumbnailImageUrl: attributes.blockThumbnailImageUrl,
    askUuid: attributes.askUuid,
    transition: attributes.transition,
    multipleChoiceOptions: attributes.multipleChoiceOptions,
    skippableChecked: attributes.skippableChecked,
  }));
};

const BlockList = ({
  collection,
  experienceUuid,
  experienceId,
  workspaceId,
}) => {
  return (
    <ReferencesProvider>
      <DragAndDropBlockList
        items={getItems(collection.data)}
        experienceUuid={experienceUuid}
        experienceId={experienceId}
        workspaceId={workspaceId}
      />
    </ReferencesProvider>
  );
};

BlockList.propTypes = {
  collection: PropTypes.object,
  experienceUuid: PropTypes.string,
  experienceId: PropTypes.number,
};

export default BlockList;
