import styled from 'styled-components';
import { ASK_EDIT_SM_BREAKPOINT } from '../../../../constants';

export const NavBar = styled.nav`
  position: absolute;
  left: 0;
  top: -3.75rem;
  width: 100%;
  display: flex;
  background-color: var(--background-primary);
  justify-content: center;
  height: 60px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;

  @media (min-width: ${ASK_EDIT_SM_BREAKPOINT}px) {
    top: 0;
    box-shadow: none;
    border-bottom: 1px solid var(--border-color-primary);

    background-color: transparent;
    z-index: 1;
  }
`;

export const NavItem = styled.a`
  display: flex;
  width: 100%;
  max-width: 122px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--font-color-primary);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.1s, opacity 0.1s;

  @media (max-width: 460px) {
    max-width: 100px;
  }

  @media (max-width: 365px) {
    max-width: 90px;
  }

  &:hover,
  &.active-link {
    color: var(--font-color-primary);
    opacity: 1;
  }

  @media (min-width: 600px) {
    max-width: 175px;
  }

  @media (min-width: ${ASK_EDIT_SM_BREAKPOINT}px) {
    max-width: 186px;

    &:last-of-type {
      display: none;
    }
  }

  &.active-link {
    position: relative;
  }

  &.active-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: var(--primary-color);

    @media (min-width: ${ASK_EDIT_SM_BREAKPOINT}px) {
      height: 2px;
    }
  }
`;

export const BackButton = styled.a`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 44px;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #727278;
  cursor: pointer;

  @media (max-width: 365px) {
    width: 25px;
  }

  &:hover {
    color: var(--font-color-primary);
  }

  @media (min-width: ${ASK_EDIT_SM_BREAKPOINT}px) {
    display: none;
  }
`;
