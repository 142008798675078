import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';
import { Title } from './style';

function DarkModal({
  isOpen,
  title,
  content,
  buttons,
  hideModal,
  customStyles,
  style,
}) {
  return (
    <Modal
      isOpen={isOpen}
      title={<Title>{title}</Title>}
      content={content}
      buttons={buttons}
      hideModal={hideModal}
      theme="dark"
      customStyles={customStyles}
      style={style}
    />
  );
}

DarkModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  buttons: PropTypes.array,
  hideModal: PropTypes.func,
  customStyles: PropTypes.shape({}),
  style: PropTypes.shape({}),
};

export default DarkModal;
