import React from 'react';
import PropTypes from 'prop-types';
import ExperienceListItem from './components/ExperienceListItem';
import { useTranslation } from '../../i18n';

function ExperiencesList({ experiences }) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ExperiencesList',
  });

  if (!experiences.length) {
    return (
      <div
        data-testid="ExperiencesList/empty"
        className="experiences_index__empty-state"
      >
        {t('noForms')}
      </div>
    );
  }
  return (
    <ul>
      {experiences.map((experience) => {
        const { attributes, links, id } = experience;
        return (
          <ExperienceListItem
            links={links}
            {...attributes}
            key={id}
          />
        );
      })}
    </ul>
  );
}

ExperiencesList.propTypes = {
  experiences: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ExperiencesList;
