require('@rails/ujs').start();
require('@rails/activestorage').start();

import 'controllers';
import ReactOnRails from 'react-on-rails';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
  '1e3528efd3e545e8bbbe4ac382df43f3T1JERVI6NDMxMjQsRVhQSVJZPTE2ODMzMTU1MTEwMDAsS0VZVkVSU0lPTj0x',
);

import DataFieldsSelector from '../components/responder/DataFieldsSelector';
import ProgressBar from '../components/responder/ProgressBar';
import AskEditPage from '../components/AskEditPage';
import PreviewWithContext from '../components/preview/PreviewWithContext';
import ArchiveFormDropdownItem from '../components/experiences/ArchiveFormDropdownItem';
import EmbedSection from '../components/experiences/EmbedSection';
import LightboxSection from '../components/experiences/LightboxSection';
import BlockList from '../components/experiences/BlockList';
import NavigationHeader from '../components/BlockForm/components/NavigationHeader';
import VideoBackground from '../components/responder/videoplayer/VideoBackground';
import BottomBar from '../components/responder/BottomBar';
import MultipleChoiceSelector from '../components/responder/MultipleChoiceSelector';
import DonationAmountsSelector from '../components/responder/DonationAmountsSelector';
import ExperienceSettings from '../components/experiences/ExperienceSettings';
import BrandingPage from '../components/settings/BrandingPage';
import BillingPage from '../components/settings/BillingPage';
import Outbox from '../components/asker/outbox';
import EditableExperienceTitle from '../components/EditableExperienceTitle';
import ExperienceTitle from '../components/ExperienceTitle';
import NewExperienceDropdown from '../components/experiences/NewExperienceDropdown';
import UploadMediaSection from '../components/UploadMediaSection';
import ActionsList from '../components/experiences/events/ActionsList';
import EditActionForm from '../components/experiences/events/EditActionForm';
import EditEventForm from '../components/experiences/events/EditEventForm';
import EventsList from '../components/experiences/events/EventsList';

import FormsPage from '../components/FormsPage';
import VideoRecord from '../components/VideoRecord';

ReactOnRails.register({
  AskEditPage,
  PreviewWithContext,
  DataFieldsSelector,
  ProgressBar,
  ArchiveFormDropdownItem,
  EmbedSection,
  LightboxSection,
  BlockList,
  NavigationHeader,
  VideoBackground,
  ActionsList,
  EditActionForm,
  BottomBar,
  MultipleChoiceSelector,
  DonationAmountsSelector,
  ExperienceSettings,
  Outbox,
  BrandingPage,
  BillingPage,
  EditEventForm,
  EventsList,
  ExperienceTitle,
  EditableExperienceTitle,
  NewExperienceDropdown,
  UploadMediaSection,
  FormsPage,
  VideoRecord,
});
