import React, { useMemo } from 'react';
import SimpleSelect from '../../../select';
import { Label, Fieldset } from '../../../globals';
import { PrimaryButton } from '../../../Button';
import { useTranslation } from '../../../../i18n';

const events = [
  {
    value: 'experience_completion',
    label: (t) => t('experienceCompletionLabel'),
  },
  {
    value: 'payment_completion',
    label: (t) => t('paymentCompletionLabel'),
  },
  {
    value: 'payment_failed',
    label: (t) => t('paymentFailedLabel'),
  },
];

function EditEventForm() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'EditEventForm',
  });

  const options = useMemo(() => {
    return events.map(({ value, label }) => ({ value, label: label(t) }));
  }, []);

  return (
    <>
      <p>New event</p>
      <Fieldset>
        <Label>When</Label>
        <SimpleSelect
          options={options}
          defaultValue={options[0]}
          isSearchable={false}
          name="experience_event[event_type]"
          classNamePrefix="react-select__events"
          placeholder="Choose an event"
          onChange={() => {}}
          isDisabled={false}
        />
      </Fieldset>
      <PrimaryButton type="submit" name="commit" responsive>
        Save
      </PrimaryButton>
    </>
  );
}

export default EditEventForm;
