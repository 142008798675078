import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MoreHorizontal, ArrowUpRight, Eye, EyeOff } from 'react-feather';
import Dropdown from '../../../dropdown';
import { useTranslation } from '../../../../i18n';
import useModal from '../../../hooks/useModal';
import HideFormliModal from '../../../modals/HideFormliModal';
import ShowFormliModal from '../../../modals/ShowFormliModal';

function VariantDropdown({ shareLink, isHidden, handleConfirm }) {
  const { isOpen, hideModal, showModal } = useModal();
  const { t } = useTranslation('translation');
  const menuItems = [
    {
      title: t('ExperienceListItem.viewVariant'),
      value: 'view',
      href: shareLink,
      icon: <ArrowUpRight size={22} />,
      target: '_blank',
    },
    {
      title: isHidden
        ? t('ExperienceListItem.show')
        : t('ExperienceListItem.hide'),
      value: 'show/hide',
      icon: isHidden ? <Eye size={22} /> : <EyeOff size={22} />,
    },
  ];

  useEffect(() => {
    hideModal();
  }, [isHidden]);

  const handleDropdownItemClick = (value) => {
    if (value === 'show/hide') {
      return showModal();
    }
  };

  return (
    <>
      <ShowFormliModal
        isOpen={isOpen && isHidden}
        hideModal={hideModal}
        handleShow={handleConfirm}
        formableType="Variant"
        content={t('ShowFormliModal.content')}
      />
      <HideFormliModal
        isOpen={isOpen && !isHidden}
        hideModal={hideModal}
        handleHide={handleConfirm}
        formableType="Variant"
        content={t('HideFormliModal.content')}
      />
      <Dropdown
        menuItems={menuItems}
        actionButton={
          <MoreHorizontal
            className="dropdown__button button-inline"
            size={22}
          />
        }
        onSelection={handleDropdownItemClick}
        position="right"
        wrapperClassName="AriaMenuButton--no-margin"
        listStyle={{ marginLeft: -24 }}
      />
    </>
  );
}

VariantDropdown.propTypes = {
  isHidden: PropTypes.bool,
  handleConfirm: PropTypes.func,
  shareLink: PropTypes.string.isRequired,
};

export default VariantDropdown;
