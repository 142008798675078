const initialState = {
  isLoading: true,
  embedCode: '',
  params: {
    dimension: 'fixed',
    background: 'visible',
    shadow: 'shadowOn',
    embedSpecificGoal: false,
    showLoadingSpinner: false,
    loadingSpinnerColor: 'rgba(255, 255, 255, 1)',
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'changeParam':
      return {
        ...state,
        isLoading: true,
        embedCode: '',
        params: {
          ...state.params,
          ...action.payload,
        },
      };
    case 'updateEmbedCode':
      return {
        ...state,
        isLoading: false,
        embedCode: action.payload.code,
      };
    default:
      throw new Error();
  }
}

export { initialState, reducer };
