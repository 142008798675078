import styled from 'styled-components';

export const PillButton = styled.div`
  background-color: var(--background-secondary);
  border: 1px solid var(--border-color-primary);
  padding: 2px 12px;
  width: 190px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.15;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-transform: capitalize;
  outline: none;
`;

export const ButtonTitle = styled.div`
  padding: 2px;
  margin: auto;
`;

export const ButtonIcon = styled.div`
  margin-top: 3px;
  margin-left: 5px;

  svg {
    stroke-width: 3;
  }
`;
