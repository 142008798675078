import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Mail, MessageSquare, ChevronUp, ChevronDown } from 'react-feather';
import MentionTitle from '../../../MentionTitle';
import Modal from '../../../modal';
import { FlexRow } from '../../../globals';
import {
  BottomWrapper,
  Container,
  IconWrapperWithBackground,
  IconWrapperWithMargin,
  TitleWrapper,
  Title,
  Subtitle,
  Label,
  Header,
  ArrowIconWrapper,
} from './style';
import Icon from '../../../shared/Icon';
import deleteIcon from '../../../icons/icon--delete--block.svg';
import editIcon from '../../../icons/icon--edit.svg';
import subscriptionIcon from '../../../icons/icon--subscription.svg';
import { deleteAction } from '../../../api';
import { useTranslation } from '../../../../i18n';
import RecurringIcons from './components/RecurringIcons';

export const ActionCard = ({ attributes, editUrl, deleteUrl }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ActionCard',
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cardIsOpen, setCardIsOpen] = useState(false);
  const {
    targetExperience,
    actionType,
    emailSubject,
    emailTos,
    textbotNumbers,
    subscriptionReference,
  } = attributes;

  const handleDeleteAction = (e) => {
    e.stopPropagation();

    deleteAction(deleteUrl).then((response) => {
      if (response.ok) {
        window.location.reload();
      }
    });

    setModalIsOpen(false);
  };

  const hideModal = () => {
    setModalIsOpen(false);
  };

  const showModal = () => {
    setModalIsOpen(true);
  };

  const modalButtons = [
    { title: 'Cancel', onClick: hideModal },
    { title: 'Delete', onClick: handleDeleteAction, primary: true },
  ];

  const labels = {
    email: {
      title: <MentionTitle title={emailSubject} />,
      subtitle: (
        <>
          To: <MentionTitle title={emailTos} />
        </>
      ),
      actionName: t('emailLabel'),
      icon: (
        <Mail
          size={18}
          strokeWidth={2.5}
          color="var(--white)"
          data-testid="ActionCard/MailIcon"
        />
      ),
      backgroundColor: '#67b0ff',
    },
    textbot: {
      title: targetExperience && (
        <MentionTitle title={targetExperience.title} />
      ),
      subtitle: (
        <>
          To: <MentionTitle title={textbotNumbers} />{' '}
        </>
      ),
      actionName: t('textbotLabel'),
      icon: (
        <MessageSquare
          size={18}
          strokeWidth={2.5}
          color="var(--white)"
          data-testid="ActionCard/MessageSquareIcon"
        />
      ),
      backgroundColor: '#4cd964',
    },
    cancel_recurring: {
      title: t('cancelReccuringLabel'),
      subtitle: <MentionTitle title={subscriptionReference} />,
      actionName: 'Update a subscription',
      icon: <Icon src={subscriptionIcon} inline />,
      backgroundColor: '#ff6667',
    },
  };

  return (
    <Container data-testid="ActionsPage/ActionCard" cardIsOpen={cardIsOpen}>
      <Header
        onClick={() => setCardIsOpen(!cardIsOpen)}
        data-testid="ActionCard/Header"
      >
        <IconWrapperWithBackground
          background={labels[actionType].backgroundColor}
        >
          {labels[actionType].icon}
        </IconWrapperWithBackground>
        <Label>{labels[actionType].actionName}</Label>
        <ArrowIconWrapper>
          {cardIsOpen ? (
            <ChevronUp
              size={16}
              strokeWidth={3}
              data-testid="ActionCard/ChevronUpIcon"
            />
          ) : (
            <ChevronDown
              size={16}
              strokeWidth={3}
              data-testid="ActionCard/ChevronDownIcon"
            />
          )}
        </ArrowIconWrapper>
        <div style={{ marginLeft: 'auto' }}>
          <RecurringIcons attributes={attributes} />
        </div>
      </Header>
      {cardIsOpen && (
        <>
          <FlexRow margin="10px 0px 0px 0px">
            <TitleWrapper>
              <Title data-testid="ActionCard/Title">
                {labels[actionType].title}
              </Title>
              <Subtitle data-testid="ActionCard/Subtitle">
                {labels[actionType].subtitle}
              </Subtitle>
            </TitleWrapper>
          </FlexRow>
          <BottomWrapper>
            <IconWrapperWithMargin
              data-testid="ActionCard/DeleteButton"
              onClick={showModal}
            >
              <Icon src={deleteIcon} />
            </IconWrapperWithMargin>
            <a href={editUrl} data-testid="ActionCard/EditButton">
              <Icon src={editIcon} />
            </a>
          </BottomWrapper>
        </>
      )}
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          title="Confirm"
          content="Are you sure you want to delete this action?"
          buttons={modalButtons}
          hideModal={hideModal}
        />
      )}
    </Container>
  );
};

ActionCard.propTypes = {
  attributes: PropTypes.shape({
    targetExperience: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    actionType: PropTypes.string,
    emailSubject: PropTypes.string,
    emailTos: PropTypes.string,
    textbotNumbers: PropTypes.string,
  }),
  editUrl: PropTypes.string,
  deleteUrl: PropTypes.string,
};
