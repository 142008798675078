import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isNil from 'lodash/isNil';
import SimpleSelect from '../../../select';
import { Label, Fieldset } from '../../../globals';
import { PrimaryButton } from '../../../Button';
import ActionFormWrapper from './components/ActionFormWrapper';
import { Container, Subtitle } from './style';
import { useTranslation } from '../../../../i18n';
import { ReferencesProvider } from '../../../../contexts/ReferencesContext';
import { EventActionProvider } from '../../../../contexts/EventActionContext';

const prepActions = (t) => [
  { value: 'email', label: t('emailLabel') },
  { value: 'textbot', label: t('textbotLabel') },
  { value: 'cancel_recurring', label: t('cancelReccuringLabel') },
];

const setSelectDefaultValue = (value, actions) => {
  return actions.find((action) => action.value === value);
};

function EditActionForm({
  isNew,
  experienceId,
  errors,
  serializedErrors,
  attributes,
  emailBuilder,
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'EditActionForm',
  });
  const [actionType, setActionType] = useState(
    attributes.actionType || 'email',
  );
  const actions = useMemo(() => prepActions(t), [t]);

  return (
    <EventActionProvider emailBuilder={emailBuilder} experienceId={experienceId}>
      <ReferencesProvider>
        <Container>
          <Subtitle>Select Action</Subtitle>
          <Fieldset>
            <Label>Action</Label>
            <SimpleSelect
              options={actions}
              defaultValue={setSelectDefaultValue(actionType, actions)}
              isSearchable={false}
              name="event_action[action_type]"
              classNamePrefix="react-select__actions"
              placeholder="Choose an action"
              onChange={({ value }) => setActionType(value)}
              isDisabled={!_isNil(attributes.id)}
            />
          </Fieldset>
          <Subtitle>Customize action</Subtitle>
          <ActionFormWrapper
            isNew={isNew}
            type={actionType}
            attributes={attributes}
            experienceId={experienceId}
            errors={errors}
            serializedErrors={JSON.parse(serializedErrors).errors}
          />
          <PrimaryButton type="submit" name="commit" responsive>
            Save
          </PrimaryButton>
        </Container>
      </ReferencesProvider>
    </EventActionProvider>
  );
}

EditActionForm.propTypes = {
  experienceId: PropTypes.number,
  errors: PropTypes.object,
  serializedErrors: PropTypes.string.isRequired,
  emailBuilder: PropTypes.shape({
    customJsUrls: PropTypes.arrayOf(PropTypes.string),
  }),
  attributes: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    actionType: PropTypes.string,
    emailBody: PropTypes.string,
    emailSubject: PropTypes.string,
    emailTos: PropTypes.string,
    emailCcs: PropTypes.string,
    emailBccs: PropTypes.string,
    senderEmail: PropTypes.string,
    senderName: PropTypes.string,
    textbotNumbers: PropTypes.string,
    targetExperience: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  }),
};

export default EditActionForm;
