import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '../../../../i18n';
import ExperienceDropdown from '../ExperienceDropdown';
import VariantDropdown from '../VariantDropdown';
import { Layers } from 'react-feather';
import { HiddenIcon } from './style';
import { hideFormByPath } from '../../../api';
import { formliReducer, initialState, SET_FORM } from './reducer';
import FormContext from './context';
import { useFormsListContext } from '../../../ExperiencesListWrapper/context';
import MentionTitle from '../../../MentionTitle';

function ExperienceListItem({
  title,
  statisticResponsesCount,
  raisedAmount,
  links,
  formableType,
  parentExperienceName,
  hidden,
  variantsCount,
}) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ExperienceListItem',
  });

  const [formState, formDispatch] = useReducer(formliReducer, initialState);
  const { isHidden, showWithVariants, hideWithVariants } = formState;
  const { getForms } = useFormsListContext();

  useEffect(() => {
    formDispatch({
      type: SET_FORM,
      payload: {
        isHidden: hidden,
        formableType,
        variantsCount,
      },
    });
  }, [hidden]);

  const handleConfirm = () => {
    const form = {
      hidden: !isHidden,
    };
    if (showWithVariants) {
      form.hide_variants = !showWithVariants;
    }
    if (hideWithVariants) {
      form.hide_variants = hideWithVariants;
    }
    hideFormByPath(links.hideLink, {
      form,
    })
      .then(() => {
        getForms();
      })
      .catch((error) => {
        window.exceptionHandler.notify(error);
      });
  };

  return (
    <FormContext.Provider value={{ formState, formDispatch }}>
      <li
        data-testid="ExperiencesList/item"
        className="experiences_index__experience-list-item"
      >
        <div className="experiences_index__experience-list-item-inner">
          <a
            data-testid="ExperienceListItem/link"
            href={formableType === 'Experience' ? links.editLink : '#'}
            className={`experiences_index__experience-list-item-title${
              isHidden ? '--secondary-color' : ''
            }`}
          >
            {title && <MentionTitle title={title} />}
            {isHidden && (
              <HiddenIcon
                data-testid="ExperienceListItem/HiddenIcon"
                size={24}
              />
            )}
          </a>
          <div className="experiences_index__experience-list-item-subtitle-wrapper">
            {statisticResponsesCount && (
              <div
                className="experiences_index__experience-list-item-subtitle"
                data-testid="ExperienceListItem/responsesCount"
              >
                {statisticResponsesCount}
              </div>
            )}
            {raisedAmount && (
              <div
                className="experiences_index__experience-list-item-subtitle"
                data-testid="ExperienceListItem/raisedAmount"
              >
                {t('raisedAmount', { amount: raisedAmount })}
              </div>
            )}
            {formableType === 'Experience' && (
              <a
                className="experiences_index__experience-list-item-subtitle"
                data-testid="ExperienceListItem/viewVariantsLink"
                href={links.viewVariantsLink}
              >
                {t('variantsLink', { count: variantsCount })}
              </a>
            )}
          </div>
          {formableType === 'Variant' && (
            <a
              className="experiences_index__experience-list-item-subtitle"
              href={links.editLink}
              data-testid="ExperienceListItem/parentExperienceName"
            >
              <Layers size={12} /> <MentionTitle title={parentExperienceName} />
            </a>
          )}
        </div>
        <div className="experiences_index__experience-list-item-dropdown experience_index__menu experience__dropdown">
          {formableType === 'Experience' && (
            <ExperienceDropdown
              isHidden={isHidden}
              handleConfirm={handleConfirm}
              {...links}
            />
          )}
          {formableType === 'Variant' && (
            <VariantDropdown
              isHidden={isHidden}
              handleConfirm={handleConfirm}
              {...links}
            />
          )}
        </div>
      </li>
    </FormContext.Provider>
  );
}

ExperienceListItem.propTypes = {
  title: PropTypes.string.isRequired,
  statisticResponsesCount: PropTypes.string,
  raisedAmount: PropTypes.string,
  links: PropTypes.shape({}).isRequired,
  formableType: PropTypes.string,
  parentExperienceName: PropTypes.string,
};

ExperienceListItem.defaultProps = {
  formableType: 'Experience',
  statisticResponsesCount: null,
  raisedAmount: null,
};

export default ExperienceListItem;
