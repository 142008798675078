import styled from 'styled-components';
import { ASK_EDIT_SM_BREAKPOINT } from '../../constants';

export const Container = styled.div`
  @media (min-width: ${ASK_EDIT_SM_BREAKPOINT}px) {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const FormWrapper = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;
  border-right: 1px solid var(--block-edit-section-border);
  background-color: var(--ask-block-edit-background);

  @media (min-width: ${ASK_EDIT_SM_BREAKPOINT}px) {
    position: relative;
    padding: 5.25rem 24px 86px;
    overflow-y: auto;
  }
`;

export const PreviewContainer = styled.div`
  display: none;
  padding-left: 12px;
  padding-right: 12px;
  background-color: var(--ask-preview-background);
  overflow-y: auto;

  @media (min-width: ${ASK_EDIT_SM_BREAKPOINT}px) {
    display: block;
  }
`;

export const PreviewWrapper = styled.div`
  position: relative;
  height: 600px;
  width: 100%;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  overflow: hidden;
`;

export const TooltipWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  color: var(--font-color-secondary--light);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  cursor: default;

  & svg {
    stroke: var(--font-color-secondary);
  }

  @media (min-width: ${ASK_EDIT_SM_BREAKPOINT}px) {
    display: flex;
    margin-top: 44px;
    margin-bottom: 24px;
  }

  @media (min-width: ${ASK_EDIT_SM_BREAKPOINT}px) and (max-width: 768px) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

export const TooltipIconWrapper = styled.div`
  display: flex;
  margin-left: 8px;
`;
