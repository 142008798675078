import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../checkbox';
import { useFormContext } from '../ExperienceListItem/context';
import { SET_SHOW_WITH_VARIANTS } from '../ExperienceListItem/reducer';
import { useTranslation } from '../../../../i18n';

function ShowVariantsCheckbox({ variantsCount }) {
  const { formState, formDispatch } = useFormContext();
  const { showWithVariants } = formState;
  const { t } = useTranslation('translation', {
    keyPrefix: 'ShowFormliModal',
  });

  useEffect(() => {
    return () => {
      formDispatch({
        type: SET_SHOW_WITH_VARIANTS,
        payload: false,
      });
    };
  }, []);

  const handleChange = () => {
    formDispatch({
      type: SET_SHOW_WITH_VARIANTS,
      payload: !showWithVariants,
    });
  };

  return (
    <>
      <p>{t('content')}</p>
      {!!variantsCount && (
        <Checkbox
          value={showWithVariants}
          checkedByDefault={showWithVariants}
          onChange={handleChange}
          label={t('showVariants', { amount: variantsCount })}
          data-testid="VariantsCheckbox"
        />
      )}
    </>
  );
}

ShowVariantsCheckbox.propTypes = {
  variantsCount: PropTypes.number.isRequired,
};

export default ShowVariantsCheckbox;
