import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MoreHorizontal,
  ArrowUpRight,
  Eye,
  Edit,
  Copy,
  Trash,
  EyeOff,
} from 'react-feather';
import Dropdown from '../../../dropdown';
import ArchiveFormModal from '../../../modals/ArchiveFormModal';
import { useTranslation } from '../../../../i18n';
import HideFormliModal from '../../../modals/HideFormliModal';
import ShowFormliModal from '../../../modals/ShowFormliModal';
import { useModal } from '../../../hooks';
import { useFormContext } from '../ExperienceListItem/context';
import ShowVariantsCheckbox from '../ShowVariantsCheckbox';
import HideVariantsCheckbox from '../HideVariantsCheckbox';
import Icon from '../../../shared/Icon';
import viewVariantsIcon from '../../../../../assets/images/dropdown_icon--variants.svg';

function ExperienceDropdown({
  shareLink,
  editLink,
  duplicateLink,
  archiveLink,
  viewVariantsLink,
  isHidden,
  handleConfirm,
}) {
  const { isOpen, hideModal, showModal } = useModal();
  const [modalName, setModalName] = useState('');
  const { t } = useTranslation('translation');

  const { formState } = useFormContext();
  const { variantsCount, formableType } = formState;

  useEffect(() => {
    hideModal();
  }, [isHidden]);

  const menuItems = [
    {
      title: t('ExperienceListItem.view'),
      value: 'view',
      href: shareLink,
      icon: <ArrowUpRight size={22} />,
      target: '_blank',
    },
    {
      title: t('ExperienceListItem.edit'),
      value: 'edit',
      href: editLink,
      icon: <Edit size={22} />,
    },
    {
      title: 'Duplicate',
      value: 'duplicate',
      href: duplicateLink,
      icon: <Copy size={22} />,
      method: 'POST',
    },
    {
      title: 'View Variants',
      value: 'variants',
      href: viewVariantsLink,
      icon: <Icon src={viewVariantsIcon} style={{ marginLeft: '5px' }} />,
    },
    {
      title: isHidden
        ? t('ExperienceListItem.show')
        : t('ExperienceListItem.hide'),
      value: 'show/hide',
      icon: isHidden ? <Eye size={22} /> : <EyeOff size={22} />,
    },
    {
      title: t('ExperienceListItem.archive'),
      value: 'archive',
      icon: <Trash size={22} />,
    },
  ];

  const handleDropdownItemClick = (value) => {
    if (value === 'archive' || value === 'show/hide') {
      setModalName(value);
      showModal();
    }
  };

  return (
    <>
      <ShowFormliModal
        isOpen={isOpen && isHidden && modalName === 'show/hide'}
        hideModal={hideModal}
        handleShow={handleConfirm}
        formableType={formableType}
        content={<ShowVariantsCheckbox variantsCount={variantsCount} />}
      />
      <HideFormliModal
        isOpen={isOpen && !isHidden && modalName === 'show/hide'}
        hideModal={hideModal}
        handleHide={handleConfirm}
        formableType={formableType}
        content={<HideVariantsCheckbox variantsCount={variantsCount} />}
      />
      <ArchiveFormModal
        isOpen={isOpen && modalName === 'archive'}
        hideModal={hideModal}
        archiveLink={archiveLink}
      />
      <Dropdown
        menuItems={menuItems}
        actionButton={
          <MoreHorizontal
            className="dropdown__button button-inline"
            size={22}
          />
        }
        onSelection={handleDropdownItemClick}
        position="right"
        wrapperClassName="AriaMenuButton--no-margin"
        listStyle={{ marginLeft: -24 }}
      />
    </>
  );
}

ExperienceDropdown.propTypes = {
  shareLink: PropTypes.string.isRequired,
  editLink: PropTypes.string.isRequired,
  duplicateLink: PropTypes.string.isRequired,
  archiveLink: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
  handleConfirm: PropTypes.func,
};

export default ExperienceDropdown;
